<template>
  <div class="animated fadeIn update_pdd_of_invoice_filtered">
    <div class="selection-section section-div">
      <div class="select-filter-json h-100 pb-3">
        <div class="form-container h-100 pb-5">
          <div class="h-100">
            <div class="flex align-items-center justify-content-space-between px-2 mx-1">
              <div
                class="label"
              >Request Body:</div>
              <div>
                <PredefinedFilterParams />
              </div>
            </div>
            <!-- <textarea class="form-control h-75" v-model="request_body"></textarea> -->
            <VJsoneditor v-model="request_body" :plus="false" class="form-control h-100" :options="{ mode: 'code' }" />
          </div>
        </div>
      </div>
      <button
        class="section-float-action-button btn btn-dark"
        @click="onTriggerSearch()"
      >Search</button>
    </div>
    <div class="selected-section section-div">
      <div class="submit-form-input-section mb-1">
        <div class="form-field">
          <div class="label">New Invoice Date:</div>
          <div class="field-input">
            <VueCtkDateTimePicker
              id=""
              class=""
              v-model="new_date_time"
              :onlyDate="true"
              :format="this.$d.date_format"
              :output-format="this.$d.date_format"
              :no-value-to-custom-elem="true"
            >
              <div>
                <b-input-group>
                  <b-input-group-prepend>
                    <slot name="prepend"></slot>
                  </b-input-group-prepend>
                  <b-form-input
                    type="text"
                    :placeholder="this.$d.date_format"
                    :value="new_date_time"
                    @input="_.debounce(this.setNewDatetime, 1500)"
                  ></b-form-input>
                  <b-input-group-append>
                    <slot name="append"></slot>
                  </b-input-group-append>
                </b-input-group>
              </div>
            </VueCtkDateTimePicker>
          </div>
        </div>
      </div>
      <div class="section-action-bar">
        <div class="submit-form-section">
          <div class="flex">
            <div
              class="list-size"
            >{{ descriptorData.Invoice && descriptorData.Invoice.length ? `${descriptorData.Invoice.length} found.` : '' }}</div>
            <div
              v-if="submit_in_progress"
              class="progress-details-toggler"
              @click="togglerProgressDetails()"
            >
              <i :class="`${this.show_progress_details ? 'icon-arrow-up' : 'icon-arrow-down'} icons font-lg`"></i>
            </div>
          </div>
        <div>
          <span class="pr-2">Que Size:</span>
          <input
            type="number"
            min="1"
            style="width: 40px"
            :value="queSize"
            @input="(event) => {
              queSize = event.target.value;
              if (submit_in_progress && selectedItems.filter(item => item._isSubmitting).length < queSize) {
                submitItem(this);
              }
            }"
          />
        </div>
        <button
          :class="'btn btn-success' + (submit_in_progress ? ' disabled' : '')"
          @click="onQueForSubmit()"
        >
          Confirm Update ({{ this.selectedItems.filter(item => !item._isSubmitted).length }})
          <q-spinner v-if="submit_in_progress" class="ml-1" color="primary" size="18px" :thickness="5" />
        </button>
      </div>
      <div :class="`progress-details-section ${this.show_progress_details && (submit_in_progress || submit_done) ? 'show' : 'hide'}`">
          <table>
            <tr class="progress-item" v-for="progress_item in this.progress_details_setting" :key="progress_item['id']">
              <td>{{ progress_item.title }}</td>
              <td>:</td>
              <td>{{ progress_item.getValue({ selectedItems }) }}</td>
              <td v-if="selectedItems.length">({{ parseInt((progress_item.getValue({ selectedItems }) / selectedItems.length) * 100) }}%)</td>
            </tr>

            <tr class="text-bold" v-if="submit_done">
              <td>Total time taken</td>
              <td>:</td>
              <td>{{ parseInt(time_taken / 1000) }} seconds</td>
            </tr>
            <tr class="text-bold" v-if="submit_done">
              <td colspan="3">
                <button
                  v-if="selectedItems.filter(item => item._error_message).length"
                  class="btn btn-sm btn-success"
                  @click="onClickExport()"
                >
                  <i class="icon-print"></i> Download Bulk Update PDF Error Report
                </button>
              </td>
            </tr>
          </table>
          <table>
            <tr class="text-bold" v-if="!submit_done && estimate_time_per_item">
              <td>Estimate Time for each Item</td>
              <td>:</td>
              <td>{{ parseInt(estimate_time_per_item / 1000) }} seconds</td>
            </tr>
            <tr class="text-bold" v-if="!submit_done && estimate_time_per_item">
              <td>Estimate Time Remaining</td>
              <td>:</td>
              <td>
                {{ parseInt((estimate_time_per_item * selectedItems.filter(item => !item._isSubmitted).length) / queSize / 1000) }} seconds
                ({{ parseInt((estimate_time_per_item * selectedItems.filter(item => !item._isSubmitted).length) / queSize / 1000 / 60) }} minutes)
              </td>
            </tr>
            <tr class="text-bold" v-if="!submit_done && estimate_time_per_item">
              <td>Estimate Complete by</td>
              <td>:</td>
              <td>{{ renderDateTimeFormat((new Date(new Date().getTime() + (estimate_time_per_item / queSize * selectedItems.filter(item => !item._isSubmitted).length))), { showSeconds: true }) }}</td>
            </tr>
          </table>
        </div>
      </div>
      <div v-if="isClassLoading.Invoice" class="class-loading-indicator p-2">Loading...</div>
      <div class="card-list mb-5" v-else>
        <div v-if="!descriptorData.Invoice || !descriptorData.Invoice.length" class="no-result p-2">No Result Found</div>
        <div
          :class="'item-card ' + (item._isSubmitted ? ' submitted' : '') + (item._isSubmitting ? ' submitting' : '') + (item._error_message ? ' has-error' : '') + (!!selectedItems.find(item2 => item2.id === item.id) ? ' selected' : '')"
          v-for="(item) in descriptorData.Invoice"
          :item="item"
          :key="item.id"
        >
          <table class="card-table">
            <tr>
              <td class="label">ID</td>
              <td class="separator">:</td>
              <td class="value">{{ item.id }}</td>
            </tr>
            <tr>
              <td class="label">Invoice Date</td>
              <td class="separator">:</td>
              <td class="value">{{ item.invoice_date }}</td>
            </tr>
            <tr>
              <td
                v-if="item._error_message"
                colspan="3"
                class="label text-red"
              >{{ item._error_message }}</td>
            </tr>
            <tr v-if="item.time_taken">
              <td class="label">Time Taken</td>
              <td class="separator">:</td>
              <td class="value">{{ parseFloat(item.time_taken / 1000).toFixed(3) }}s</td>
            </tr>
          </table>
          <button
            v-if="selectedItems.find(item2 => item2.id === item.id)"
            :class="`btn btn-danger ${submit_in_progress ? 'disabled' : ''}`"
            @click="onUnselectItem({ item })"
          ><i class="icon-minus"></i></button>
          <button
            v-else
            :class="`btn btn-primary ${submit_in_progress ? 'disabled' : ''}`"
            @click="onSelectItem({ item })"
          ><i class="icon-plus"></i></button>
        </div>
      </div>
      <div class="section-float-action-button">
        <button
          :class="'btn btn-dark mr-2' + (submit_in_progress ? ' disabled' : '')"
          @click="onSelectAll()"
        >Select All</button>
        <button
          :class="'btn btn-dark' + (submit_in_progress ? ' disabled' : '')"
          @click="onUnselectAll()"
        >Unselect All</button>
      </div>
    </div>
  </div>
</template>

<script>
import { QSpinner } from 'quasar';
import Fuse from 'fuse.js';
// import _ from 'lodash';
// import moment from 'moment';
import vSelect from "vue-select";
import PredefinedFilterParams from './PredefinedFilterParams';
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import VJsoneditor from 'v-jsoneditor';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
import { dataChecking, renderDateTimeFormat, exportToCsv } from '@/descriptor/coreui/globalUtils';

export default {
  components: {
    QSpinner,
    vSelect,
    PredefinedFilterParams,
    VueCtkDateTimePicker,
    VJsoneditor,
  },
  props: {

  },
  data: () => {
    return {
      progress_details_setting: [
        {
          id: 'pending',
          title: 'Total Pending',
          getValue: ({ selectedItems }) => selectedItems.filter(item => !item._isSubmitted).length,
        },
        {
          id: 'error',
          title: 'Total Error',
          getValue: ({ selectedItems }) => selectedItems.filter(item => item._error_message).length,
        },
        {
          id: 'done',
          title: 'Total Done',
          getValue: ({ selectedItems }) => selectedItems.filter(item => item._isSubmitted).length,
        },
      ],
      show_progress_details: true,
      new_date_time: null,
      selectedItems: [],
      submitQue: [],
      queSize: 1,
      descriptorData: {},
      submit_in_progress: false,
      estimate_time_per_item: 0,
      item_done_for_calculate_estimation: 0,
      submit_done: false,
      start_time: null,
      time_taken: null,
      request_body: {},
      error_message: {},
      isClassLoading: {},
      isSubmiting: {},
    };
  },
  computed: {

  },
  created() {
    this.init();
  },
  methods: {
    dataChecking,
    renderDateTimeFormat,
    exportToCsv,
    init() {
      // const now = new Date();
      // now.setSeconds(0);
      // this.new_date_time = moment(now).format(this.$d.date_format);
    },
    onClickExport() {
      if (this.selectedItems.length === 0) return;

      const csvHeaderRows = [
        'ID',
        'Error Message'
      ];
      const csvDataRows = this.selectedItems.filter(item => item._error_message).map((item) => [
        item.id,
        item._error_message
      ]);

      this.exportToCsv('Error report', [csvHeaderRows, ...csvDataRows]);
    },
    setNewDatetime(input) {
      this.new_date_time = input;
    },
    fuseSearch(options, search) {
      const fuse = new Fuse(options, {
        keys: ['id', 'name'],
        shouldSort: true,
      });
      const result = search.length ? fuse.search(search).map((item) => item.item) : fuse.list;
      return result;
    },
    onTriggerSearch() {
      this.time_taken = 0;
      this.submit_done = false;
      this.estimate_time_per_item = null;
      this.item_done_for_calculate_estimation = 0;

      this.fireDescriptorAPI({
        cache_key: 'searchResult',
        request_type: 'index',
        class_name: 'Invoice',
        request_body: {
          ...this.request_body,
        },
      });
    },
    togglerProgressDetails() {
      this.show_progress_details = !this.show_progress_details;
    },
    onSelectAll() {
      if (this.submit_in_progress) {
        return;
      }

      this.selectedItems = [...new Set([...this.selectedItems, ...this.descriptorData.Invoice])];
      this.$forceUpdate();
    },
    onUnselectAll() {
      if (this.submit_in_progress) {
        return;
      }

      this.selectedItems = [];
      this.$forceUpdate();
    },
    onClearDone() {
      const newArray = this.selectedItems.filter((item) => !item._isSubmitted);
      this.selectedItems = newArray;
    },
    onQueForSubmit() {
      if (!this.new_date_time) {
        alert('Please select new incoice date');
        return;
      }

      const scope = this;
      this.selectedItems.forEach((item) => {
        if (item._isSubmitted) {
          return;
        }

        scope.submitQue.push(item);
      });

      if (scope.submitQue.length) {
        scope.submit_done = false;
        scope.submit_in_progress = true;
        scope.start_time = new Date();

        scope.submitItem(scope);
      }
    },
    submitItem: (passedScope) => {
      const scope = passedScope || this;

      if (!scope.submitQue.length && !scope.selectedItems.filter(item => item._isSubmitting).length) {
        scope.submit_in_progress = false;
        scope.submit_done = true;
        scope.time_taken = (new Date() - scope.start_time) + scope.time_taken;

        return;
      }

      const item = scope.submitQue.splice(0, 1)[0];
      if (item._isSubmitted) {
        return;
      }

      item._start_time = new Date();
      item._isSubmitting = true;
      item._error_message = null;
      scope.$forceUpdate(); // TODO: dont use forceUpdate

      if (scope.selectedItems.filter(item => item._isSubmitting).length < scope.queSize) {
        scope.submitItem(scope);
      }

      scope.fireDescriptorAPI({
        cache_key: 'updateItem',
        request_type: 'update',
        class_name: 'invoice',
        request_body: {
          _no_read: true,
          id: item.id,
          invoice_date: scope.new_date_time,
        },
        onSuccess: () => {
          const now = new Date();
          const targetItem = scope.selectedItems.find((item2) => item2.id === item.id);
          targetItem._isSubmitting = false;
          targetItem._isSubmitted = true;
          targetItem.id = `${targetItem.id}-submitted (${now.toLocaleString()})`;

          targetItem.time_taken = (now - targetItem._start_time);
          scope.estimate_time_per_item = ((scope.estimate_time_per_item * scope.item_done_for_calculate_estimation) + targetItem.time_taken) / (scope.item_done_for_calculate_estimation + 1);
          scope.item_done_for_calculate_estimation++;

          scope.$forceUpdate();
          if (scope.selectedItems.filter(item => item._isSubmitting).length < scope.queSize) {
            scope.submitItem(scope);
          }
        },
        onError: ({ error_message }) => {
          item._error_message = error_message;
          item._isSubmitting = false;

          scope.$forceUpdate();
          scope.submitQue.splice(0, 1);
          if (scope.selectedItems.filter(item => item._isSubmitting).length < scope.queSize) {
            scope.submitItem(scope);
          }
        }
      });
    },
    onSelectItem({ item }) {
      if (this.submit_in_progress) {
        return;
      }

      this.selectedItems.push(item);
    },
    onUnselectItem({ item }) {
      if (this.submit_in_progress) {
        return;
      }

      const indexOfSelected = this.selectedItems.findIndex((item2) => item === item2);
      this.selectedItems.splice(indexOfSelected, 1);
    },

    // start util functions
    fireDescriptorAPI({ cache_key, request_type = 'index', request_body = {}, class_name, onSuccess = null, onError = null }) {
      const scope = this;

      this.isClassLoading[class_name] = true;
      this.$forceUpdate();

      this.$d.request(
        request_type,
        class_name,
        request_body,
        (response) => {
          if (onSuccess instanceof Function) {
            onSuccess({ response });
          } else if (request_type === 'index') {
            scope.$d.setCache(cache_key || `${request_type}-${class_name}`, response);

            scope.descriptorData[class_name] = response;
            scope.error_message[class_name] = null;
            scope.isClassLoading[class_name] = null;

            scope.$forceUpdate();
          }
        },
        (error_message, cancelled) => {
          console.error(error_message);

          if (error_message) {
            scope.error_message[class_name] = error_message;
          }

          if (cancelled) {
            //do nothign if cancelled
          } else if (!scope.error_message[class_name]) {
            scope.error_message[class_name] = scope.$api.defaultErrorMessage;
          }

          if (onError instanceof Function) {
            onError({ error_message });
          }
        },
        () => {
          scope.isClassLoading[class_name] = false;
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';
@import 'node_modules/bootstrap/scss/mixins/_breakpoints';

.justify-content-space-between {
  justify-content: space-between;
}

.update_pdd_of_invoice_filtered {
  display: flex;
  margin: 0.25em;
  height: calc(100vh - 0.5em - 78px - 32px - 55px - 43px);
  font-size: 88%;
  max-width: 1140px;
  margin: auto;

  @media (max-width: 575px) {
    flex-direction: column;

    .section-div {
      width: calc(100% - 0.7em) !important;
    }
  }

  .selected-section {
    display: flex;
    flex-direction: column;

    .form-field {
      display: flex;
      align-items: center;

      .field-input {
        flex: 1;
        margin: 0 0.5em;
      }
    }
  }

  .section-div {
    width: 50%;
    margin: 0.25em 0.35em;
    padding: 0.5em;
    border-radius: 5px;
    border: 1px solid gray;
    background-color: #F5F5F5;
    display: flex;
    flex-direction: column;
    position: relative;
    min-height: 250px;

    .section-action-bar {
      border-bottom: 1px solid gray;
      margin: 0 -0.5em;
      padding: 0 0.75em;
      padding-bottom: 0.5em;

      .submit-form-section {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .list-size {
        font-size: 120%;
        font-weight: bolder;
      }

      .progress-details-toggler {
        padding: 0.25em;
        margin-left: 0.5em;
        cursor: pointer;
      }

      .form-container {
        flex: 1;
      }

      .progress-details-section {
        display: none;

        &.show {
          display: block;
        }
      }
    }

    .section-float-action-button {
      position: absolute;
      bottom: 0.5em;
      right: 0.5em;
    }

    .card-list {
      overflow: auto;

      .item-card {
        margin: 0.25em 0;
        padding: 0.25em 0.5em;
        border-radius: 5px;
        border: 2px solid lightgray;
        background-color: white;
        display: flex;

        &.selected {
          border-color: gray;
          background-color: #EAEAEA;
        }

        .card-table {
          width: 100%;

          tr {
            .label {
              width: 100px;
            }

            .value {
              font-weight: bolder;
            }

            .separator {
              width: 0.5em
            }
          }
        }

        &.has-error {
          border-color: red;
        }

        &.submitting {
          position: relative;

          &:after {
            display: flex;
            justify-content: center;
            align-items: center;
            content: 'submitting';
            font-weight: bolder;
            font-size: 2em;
            color: RGBA(255, 255, 255, 0.6);
            background-color: RGBA(50, 50, 50, 0.3);
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            position: absolute;
          }
        }
        &.submitted {
          border-color: green;
          position: relative;

          &:after {
            display: flex;
            justify-content: center;
            align-items: center;
            content: 'submitted';
            font-weight: bolder;
            font-size: 2em;
            color: RGBA(255, 255, 255, 0.6);
            background-color: RGBA(0, 50, 0, 0.3);
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            position: absolute;
          }
          .btn.btn-primary {
            display: none;
          }
        }
      }
    }
  }
}
</style>
